import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  CheckboxGroup,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Link,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spacer,
  Text,
  Textarea,
  useToast,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import * as constants from "../bbb/constants";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import traitRerollAbi from "../../abis/traitRerollAbi.json";
import bbbAbi from "../../abis/bbb.json";
import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { IpfsImage } from "react-ipfs-image";
import { Card } from "@chakra-ui/card";
import Navbar from "../../components/Navbar";
import bruhAbi from "../../abis/bruh.json";

function shorten(text) {
  return text.substring(0, 5) + "..." + text.substring(39, 42);
}
let provider;
let providerWC = new WalletConnectProvider({
  rpc: { 43114: "https://api.avax.network/ext/bc/C/rpc" },
  chainId: 43114,
});
window.ethereum
  ? (provider = new ethers.providers.Web3Provider(window.ethereum))
  : (provider = new ethers.providers.Web3Provider(providerWC));

const connect = async () => {
  window.ethereum
    ? await provider.send("eth_requestAccounts", [])
    : await providerWC.enable();
};
const providerMainnet = new ethers.getDefaultProvider(
  "https://api.avax.network/ext/bc/C/rpc"
);
const providerTestnet = new ethers.getDefaultProvider(
  "https://api.avax-test.network/ext/bc/C/rpc"
);
const signer = provider.getSigner();

/*await Moralis.start({
    apiKey: "rTO3ivS5kbrUYLADjiK07aprt07UIKh9lcdEy346azAzfSGi6zfYLBA4z9C1MuE4",
  });
  
  async function GetNFTs() {
    const response = await Moralis.EvmApi.nft.getWalletNFTs({
      address: await signer.getAddress(),
      chain: chain,
    });
    
    return response.result;
  }*/

export default function Edit() {
  const { id } = useParams();
  const [network, setNetwork] = useState();

  const changeNetwork = async () => {
    if (network != 43114 && window.ethereum) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0xa86a",
          },
        ],
      });
    } else if (network != 43114 && !window.ethereum) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0xa86a",
          },
        ],
      });
    }
  };

  const toast = useToast();
  const infoToast = (heading, info) => {
    const id = Date.now().toString();
    toast({
      duration: 18000,
      position: "bottom-left",
      id: id,
      render: () => (
        <>
          <Box
            shadow={"dark-lg"}
            rounded={"3xl"}
            bgColor="black"
            border={"2px"}
            padding={"0.5"}
            justifyContent="center"
            justifyItems={"center"}
            justifySelf={"center"}
            alignContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            textAlign="center"
            verticalAlign="center"
          >
            <HStack>
              <Heading flex={1} fontSize={"2xl"}>
                {heading}
              </Heading>
              <IconButton
                onClick={() => {
                  toast.close(id);
                }}
                rounded="full"
                colorScheme={"blackAlpha"}
              >
                <CloseIcon />
              </IconButton>
            </HStack>
            <Text>{info}</Text>
          </Box>
        </>
      ),
    });
  };
  const txToast = (txhash) => {
    const id = Date.now().toString();
    toast({
      duration: 18000,
      position: "bottom-left",
      id: id,
      render: () => (
        <>
          <Box
            shadow={"dark-lg"}
            rounded={"3xl"}
            bgColor="black"
            border={"2px"}
            padding={"0.5"}
            justifyContent="center"
            justifyItems={"center"}
            justifySelf={"center"}
            alignContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            textAlign="center"
            verticalAlign="center"
          >
            <HStack>
              <Heading flex={1} fontSize={"2xl"}>
                Success!
              </Heading>
              <IconButton
                onClick={() => {
                  toast.close(id);
                }}
                rounded="full"
                colorScheme={"blackAlpha"}
              >
                <CloseIcon />
              </IconButton>
            </HStack>
            <Text>
              view your tx :{" "}
              <Link
                textColor={"red"}
                href={"https://snowtrace.io/tx/" + txhash}
                isExternal
              >
                Link
              </Link>{" "}
            </Text>
          </Box>
        </>
      ),
    });
  };
  const errorToast = (reason) => {
    const id = Date.now().toString();
    toast({
      isClosable: true,
      id: id,
      title: "Error",
      description: reason,
      duration: 18000,
      position: "bottom-left",
    });
  };

  const handleError = (error) => {
    errorToast(error.reason||error.message);
  };

  const [bbbTokens, setBbbTokens] = useState([]);
  const [BruhBalance, setBruhBalance] = useState(0);
  const [avaxBalance, setAvaxBalance] = useState(0);
  const [userAddress, setUserAddress] = useState();
  const [nfts, setNfts] = useState([]);
  const [selected, setSelected] = useState();
  const [rerollApproved, setRerollApproved] = useState(false);
  const [tokenURI, setTokenURI] = useState();
  const [tokenIds, setTokenIds] = useState([]);
  const [metadata, setMetadata] = useState();
  const [approved, setApproved] = useState(0);
  const [rerollCost, setRerollCost] = useState(0);
  const [bruhCost, setBruhCost] = useState(0);
  const [selectedForTransfer, setSelectedForTransfer] = useState();
  const [transferredTrait, setTransferredTrait] = useState();
  const [value, setValue] = useState(0);
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [nothing, doNothing] = useState();
  const BBBnoSigner = useMemo(()=> new ethers.Contract(
    "0x84FaB74795F51C3208379924c76abE6e5A36D392",
    bbbAbi,
    providerMainnet
  ),  [])

  const bruhContract = useMemo(()=> new ethers.Contract('0xefb0475757652c9dd7B8bE2C6e0d99fB05950aB1', bruhAbi, signer), [])

  const rerollContract = useMemo(()=> new ethers.Contract(
    "0x2F1420fA8B9685C88fF07fD294dEe73Ba4106D4D",
    traitRerollAbi,
    providerMainnet
  ),  [])
  const BBB = BBBnoSigner.connect(signer);
  const reroll = rerollContract.connect(signer);
  useEffect(() => {
    signer.getChainId().then((e) => setNetwork(e));
    signer.getAddress().then((e) => setUserAddress(e));
    rerollContract.rerollPriceAvax().then((e) => setRerollCost(e));
    rerollContract.rerollPriceBruh().then((e) => setBruhCost(e));
    !tokenURI?BBBnoSigner.tokenURI(id).then((e) =>{
      setTokenURI(e.replace("ipfs://", "https://gateway.ipfs.io/ipfs/"))
  }):doNothing()
    if (userAddress && !nfts?.length > 0) {
      BBBnoSigner.tokensOfOwner(userAddress).then((e) => {
        setNfts(e);
        e.forEach((e) => {
          BBBnoSigner.tokenURI(e).then((w) => {
            tokenIds[e]=Number(w.split("/")[4])
        })
      })
    });
    }
    bruhContract.allowance(userAddress, "0x2F1420fA8B9685C88fF07fD294dEe73Ba4106D4D").then((e) => setApproved(ethers.utils.formatEther(e)));
    BBBnoSigner.isApprovedForAll(userAddress, "0x2F1420fA8B9685C88fF07fD294dEe73Ba4106D4D").then(async(e) => setRerollApproved(e));

    if(!metadata){
      fetch(tokenURI, { method: "GET" })
        .then((res) => res.json())
        .then((e) => setMetadata(e));
      }
      if(!name)setName(metadata?.name);
      if(!description)setDescription(metadata?.description);
  }, [BBB, BBBnoSigner, bruhContract, description, id, metadata, name, nfts, rerollContract, tokenIds, tokenURI, userAddress]);







  const rerollTrait = async () => {
    if (Number(approved) < 100) {
      const tx = await bruhContract.approve('0x2F1420fA8B9685C88fF07fD294dEe73Ba4106D4D', ethers.utils.parseEther("1000000000000000"));
      const rc = await tx.wait();
    }
    const tx = await reroll.rerollWithBruh(id, selected).catch(handleError)
    const rc = await tx.wait();
    infoToast("success", "");
  };
  const rename = async () => {
    if (Number(approved) < 100) {
      const tx = await bruhContract.approve('0x2F1420fA8B9685C88fF07fD294dEe73Ba4106D4D', ethers.utils.parseEther("1000000000000000"));
      const rc = await tx.wait();
    }
    const tx = await reroll.renameWithBruh(id, name).catch(handleError)
    const rc = await tx.wait();
    infoToast("success", "");
  };
  const changeDesc = async () => {
    if (Number(approved) < 100) {
      const tx = await bruhContract.approve('0x2F1420fA8B9685C88fF07fD294dEe73Ba4106D4D', ethers.utils.parseEther("1000000000000000"))
      const rc = await tx.wait();
    }
    const tx = await reroll.changeDescriptionWithBruh(id, description).catch(handleError)
    const rc = await tx.wait();
    infoToast("success", "");
  };

  const transferTrait = async () => {
    if(!rerollApproved){
      const tx = await BBB.setApprovalForAll("0x2F1420fA8B9685C88fF07fD294dEe73Ba4106D4D", true)
      const rc = await tx.wait();
    }
    const tx = await reroll.traitTransfer(selectedForTransfer,id,  transferredTrait).catch(console.log)
    const rc = await tx.wait();
    infoToast("success", "");
  };

  const legendaryTokenIds = [3000]
  if (id > 2999 || id < 0 || !isFinite(id)) {
    return "error";
  } else {
    return (
      <>
        <Navbar />
        <div className="mt-4 lg:mt-0 px-4 sm:px-6 max-w-4xl mx-auto text-gray-800 relative z-40">
          <div className="flex justify-between items-center w-full py-4 px-4 bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg mb-2">
            <h1 className="text-3xl font-extrabold">{metadata?.name}</h1>

            <a
              href={
                "https://joepegs.com/item/avalanche/0x84fab74795f51c3208379924c76abe6e5a36d392/" +
                id
              }
              
              className="text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl"
            >
              View on Joepegs
            </a>
          </div>

          <div className="custom-grid overflow-hidden py-4 px-4 bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg">
            {/* <div className="grid overflow-hidden py-4 px-4 grid-cols-2 auto-rows-auto gap-4 grid-flow-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"> */}
            <div className="flex flex-col lg:flex-row gap-2">
              <img
                className="rounded-lg w-1/2 h-fit"
                src={
                  "https://pub-b13d985639f64fc8810c584b510cea1b.r2.dev/images/"+tokenURI?.split("/")[4] + ".jpg"
                }
                alt=''
              />
              <div className="w-full">
                <div className="flex flex-col">
                  <label className="font-extrabold mb-1" for="nftname">
                    Name
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    name="nftname"
                    id="nftname"
                    className="rounded-lg p-2"
                  />
                  <Tooltip title="ass">

                  <button
                    isDisabled={
                      true
                      // metadata?.image ==
                      // "ipfs://bafybeiglyxykpokwhkcjrptn364tu373bunp73ehbeg7gqslbwvdgrn7ey/0.gif"
                    }
                    //onClick={() => rename()}
                    className="my-2 w-full text-gray-600 opacity-40 cursor-not-allowed nohover:bg-gray-300 nohover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                    >
                    Rename
                  </button>
                    </Tooltip>
                </div>
                <div className="flex flex-col">
                  <label className="font-extrabold mb-1" for="nftdesc">
                    Description
                  </label>
                  <textarea
                    value={description}
                    rows="3"
                    className="rounded-lg p-2"
                    onChange={(e) => setDescription(e.target.value)}
                    name="nftdesc"
                    id="nftdesc"
                  ></textarea>
                  
                  <button
                    isDisabled={
                      true
                      // metadata?.image ==
                      // "ipfs://bafybeiglyxykpokwhkcjrptn364tu373bunp73ehbeg7gqslbwvdgrn7ey/0.gif"
                    }
                    //onClick={() => changeDesc()}
                    className="my-2 w-full text-gray-600 opacity-40 cursor-not-allowed nohover:bg-gray-300 nohover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                  >
                    Update Description
                  </button>
                </div>
              </div>
            </div>
            <div className="border-l-[1px] border-white">
              <div className="flex w-full pl-4 pr-2 flex-col mt-2 lg:mt-0">
                <p className="font-extrabold mb-2">Choose trait to reroll</p>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="background"
                    value={0}
                    onChange={(e) => setSelected(e.target.value)}
                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="background"
                  >
                    Background
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="skeletoncolor"
                    value={1}
                    onChange={(e) => setSelected(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="skeletoncolor"
                  >
                    Skeleton Color
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="costume"
                    value={2}
                    onChange={(e) => setSelected(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="costume"
                  >
                    Costume
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="eyes"
                    value={3}
                    onChange={(e) => setSelected(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="eyes"
                  >
                    Eyes
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="glasses"
                    value={4}
                    onChange={(e) => setSelected(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="glasses"
                  >
                    Glasses
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="hat"
                    value={5}
                    onChange={(e) => setSelected(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="hat"
                  >
                    Hat
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="mouth"
                    value={6}
                    onChange={(e) => setSelected(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="mouth"
                  >
                    Mouth
                  </label>
                </div>
                <button
                  className="mt-4 w-full text-gray-600 opacity-40 cursor-not-allowed nohover:bg-gray-300 nohover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                  isDisabled={
                    (legendaryTokenIds.indexOf(id) == -1 ? false : true) ||
                    metadata?.image ==
                    "ipfs://bafybeiglyxykpokwhkcjrptn364tu373bunp73ehbeg7gqslbwvdgrn7ey/0.gif"
                  }
                  //onClick={() => rerollTrait()}
                >
                  Reroll Trait
                </button>
                <p className="text-black"> Rerolls are disabled{/*Each change costs {ethers.utils.formatEther(bruhCost.toString())} $bruh2*/}</p>
                {/*<Link textColor={'blue'} sx={{ _hover: { textDecoration: 'underline' } }} isExternal href="https://traderjoexyz.com/avalanche/trade?outputCurrency=0xefb0475757652c9dd7b8be2c6e0d99fb05950ab1">Buy $bruh2</Link>*/}
              </div>
            </div>
          </div>
          <div className="custom-grid py-4 px-4 bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg">
            {/* <div className="grid overflow-hidden py-4 px-4 grid-cols-2 auto-rows-auto gap-4 grid-flow-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"> */}

                <div className="flex flex-col lg:flex-row gap-2">
                <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                    {nfts?.map((nft) => {
                      if(parseInt(nft._hex) != id)
                      return (
                        <GridItem
                    className="mt-4 w-full text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                          flexDir={'column'}
                          onClick={() => {
                            setSelectedForTransfer(parseInt(nft._hex));
                          }}
                        >
                          <Image
                            className="rounded-lg w-1/2 h-fit"
                            width={'600px'}                         
                            src={
                              'https://pub-b13d985639f64fc8810c584b510cea1b.r2.dev/images/' +
                              tokenIds[nft] + ".jpg"
                              }
                            alt=''
                              />
                          <Text>#{parseInt(tokenIds[nft])}</Text>
                          <Checkbox isChecked={selectedForTransfer == parseInt(nft._hex)} />
                        </GridItem>
                      )}
                    )}
                    </Grid>
            <div className="border-l-[1px] border-white">
              <div className="flex w-full pl-4 pr-2 flex-col mt-2 lg:mt-0">
                <p className="font-extrabold mb-2">Choose trait to transfer</p>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="background"
                    value={0}
                    onChange={(e) => setTransferredTrait(e.target.value)}
                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="background"
                  >
                    Background
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="skeletoncolor"
                    value={1}
                    onChange={(e) => setTransferredTrait(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="skeletoncolor"
                  >
                    Skeleton Color
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="costume"
                    value={2}
                    onChange={(e) => setTransferredTrait(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="costume"
                  >
                    Costume
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="eyes"
                    value={3}
                    onChange={(e) => setTransferredTrait(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="eyes"
                  >
                    Eyes
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="glasses"
                    value={4}
                    onChange={(e) => setTransferredTrait(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="glasses"
                  >
                    Glasses
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="hat"
                    value={5}
                    onChange={(e) => setTransferredTrait(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="hat"
                  >
                    Hat
                  </label>
                </div>
                <div className="flex items-center cursor-pointer mb-1">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="radio"
                    id="mouth"
                    value={6}
                    onChange={(e) => setTransferredTrait(e.target.value)}

                  />
                  <label
                    className="inline-block text-gray-800 cursor-pointer"
                    for="mouth"
                  >
                    Mouth
                  </label>
                </div>
                <button
                  className="mt-4 w-full text-gray-600 opacity-40 cursor-not-allowed nohover:bg-gray-300 nohover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                  isDisabled={
                    (legendaryTokenIds.indexOf(id) == -1 ? false : true) ||
                    metadata?.image ==
                    "ipfs://bafybeiglyxykpokwhkcjrptn364tu373bunp73ehbeg7gqslbwvdgrn7ey/0.gif"
                  }
                  //onClick={() => transferTrait()}
                >
                  Transfer Trait
                </button>
                <p className="text-black">Transfers are disabled</p>
              </div>
              </div>
              </div>
              </div>
            </div>
      </>
    );
  }
}
