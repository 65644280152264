/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useMemo, useState } from "react";
import { FaDiscord, FaTwitter } from "react-icons/fa";

import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Spinner,
  Text,
  Tooltip,
  useToast,
  VStack,
} from "@chakra-ui/react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import abi from "./bbb.json";
import keccak256 from "keccak256";
import MerkleTree from "merkletreejs";
import T1WL from "./T1WL";
import T2WL from "./T2WL";
import T3WL from "./T3WL";
import { CloseIcon, SettingsIcon } from "@chakra-ui/icons";
import DiscordIcon from "../../icons/discord";
import TwitterIcon from "../../icons/twitter";
import { Card } from "@chakra-ui/card";
import Navbar from "../../components/Navbar";
function shorten(text) {
  return text.substring(0, 5) + "..." + text.substring(39, 42);
}

function BBB() {
  let provider;
  let providerWC = new WalletConnectProvider({
    rpc: { 43114: "https://api.avax.network/ext/bc/C/rpc" },
    chainId: 43114,
  });
  window.ethereum
    ? (provider = new ethers.providers.Web3Provider(window.ethereum))
    : (provider = new ethers.providers.Web3Provider(providerWC));

  const connect = async () => {
    window.ethereum
      ? await provider.send("eth_requestAccounts", [])
      : await providerWC.enable();
    await changeNetwork();
  };
  const providerMainnet = new ethers.getDefaultProvider(
    "https://api.avax.network/ext/bc/C/rpc"
  );
  const providerTestnet = new ethers.getDefaultProvider(
    "https://api.avax-test.network/ext/bc/C/rpc"
  );
  const contractAddress = "0x84FaB74795F51C3208379924c76abE6e5A36D392";
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contractAddress, abi, providerMainnet);
  const withSigner = contract.connect(signer);
  const [amount, setAmount] = useState(1);
  const [royalty, setRoyalty] = useState(500);
  const [price, setPrice] = useState();
  const [wlPrice, setWlPrice] = useState();
  const [maxMintable, setMaxMintable] = useState(3000);
  const [max, setMax] = useState(1);
  const [maxWL, setMaxWl] = useState();
  const [wlTime, setWlTime] = useState();
  const [publicTime, setPublicTime] = useState();
  const [isDisabled, setDisabled] = useState(false);
  const [connectMessage, setConnectMessage] = useState("Connect Wallet");
  const [address, setAddress] = useState("");
  const [network, setNetwork] = useState(0);
  const [nothing, doNothing] = useState();

  let totalPrice = price * amount;

  const [minted, setMinted] = useState(0);
  const [revealed, setRevealed] = useState(0);
  const [whitelisted, setWhitelisted] = useState();

  const buf2hex = (x) => "0x" + x.toString("hex");
  const leavesT1 = T1WL.map((x) => keccak256(x));
  const leavesT2 = T2WL.map((x) => keccak256(x));
  const leavesT3 = T3WL.map((x) => keccak256(x));
  const treeT1 = new MerkleTree(leavesT1, keccak256, { sortPairs: true });
  const treeT2 = new MerkleTree(leavesT2, keccak256, { sortPairs: true });
  const treeT3 = new MerkleTree(leavesT3, keccak256, { sortPairs: true });
  const leaf = keccak256(address).toString("hex");

  let proof;
  if (T3WL.includes(address)) {
    proof = treeT3.getProof(leaf).map((x) => buf2hex(x.data));
  } else if (T2WL.includes(address)) {
    proof = treeT2.getProof(leaf).map((x) => buf2hex(x.data));
  } else {
    proof = treeT1.getProof(leaf).map((x) => buf2hex(x.data));
  }

  useMemo(() => {
    contract.lastTokenRevealed().then((e) => setRevealed(parseInt(e)));
    contract.totalSupply().then((e) => setMinted(parseInt(e._hex)));
    contract
      .isWhitelisted(proof, address)
      .then((e) => setWhitelisted(parseInt(e._hex)));
    contract.price().then((e) => setPrice(ethers.utils.formatEther(e)));
    contract
      .tierPrice(whitelisted - 1)
      .then((e) => setWlPrice(ethers.utils.formatEther(e)));
    contract.tierMints(whitelisted - 1).then((e) => setMaxWl(parseInt(e._hex)));
    contract.maxPerTX().then((e) => setMax(parseInt(e._hex)));
    contract.publicTime().then((e) => setPublicTime(parseInt(e._hex)));
    contract.wlTime().then((e) => setWlTime(parseInt(e._hex)));
    contract.maxMintable().then((e) => setMaxMintable(parseInt(e._hex)));
  }, [address, contract, proof, whitelisted]);

  useEffect(() => {
    if (address) {
      setConnectMessage("Connected as: " + shorten(address));
    }
    signer.getAddress().then((value) => setAddress(value));
    provider.getNetwork().then((value) => setNetwork(value.chainId));
  }, [address, provider, signer]);

  function handleError(err) {
    setDisabled(false);
    console.log(err);
    errorToast(err.reason || err.data.message);
  }
  const txToast = (txhash) => {
    const id = Date.now().toString();
    toast({
      duration: 18000,
      position: "bottom-left",
      id: id,
      render: () => (
        <>
          <Box
            shadow={"dark-lg"}
            rounded={"3xl"}
            bgColor="black"
            border={"2px"}
            padding={"0.5"}
            justifyContent="center"
            justifyItems={"center"}
            justifySelf={"center"}
            alignContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            textAlign="center"
            verticalAlign="center"
          >
            <HStack>
              <Heading flex={1} fontSize={"2xl"}>
                Success!
              </Heading>
              <IconButton
                onClick={() => {
                  toast.close(id);
                }}
                rounded="full"
                colorScheme={"blackAlpha"}
              >
                <CloseIcon />
              </IconButton>
            </HStack>
            <Text>
              view your tx :{" "}
              <Link
                textColor={"red"}
                href={"https://snowtrace.io/tx/" + txhash}
                isExternal
              >
                Link
              </Link>{" "}
            </Text>
          </Box>
        </>
      ),
    });
  };

  const mint = async () => {
    if (network == 43114) {
      setDisabled(true);
      const tx = await withSigner
        .mint(amount, royalty, {
          value: ethers.utils.parseEther(totalPrice.toString()),
        })
        .catch(handleError);

      const receipt = await tx.wait().catch(handleError);
      setDisabled(false);
      console.log(receipt);
      if (receipt.status == 1) txToast(receipt.transactionHash);
    } else {
      changeNetwork();
      errorToast("Change network to Avalanche mainnet");
    }
  };
  const mint1 = async () => {
    if (network == 43114) {
      setDisabled(true);
      const tx = await withSigner
        .tier1Mint(amount, royalty, proof, {
          value: ethers.utils.parseEther(totalPrice.toString()),
        })
        .catch(handleError);

      const receipt = await tx.wait().catch(handleError);
      setDisabled(false);
      console.log(receipt);
      if (receipt.status == 1) txToast(receipt.transactionHash);
    } else {
      changeNetwork();
      errorToast("Change network to Avalanche mainnet");
    }
  };
  const mint2 = async () => {
    if (network == 43114) {
      setDisabled(true);
      const tx = await withSigner
        .tier2Mint(amount, royalty, proof, {
          value: ethers.utils.parseEther(totalPrice.toString()),
        })
        .catch(handleError);

      const receipt = await tx.wait().catch(handleError);
      setDisabled(false);
      console.log(receipt);
      if (receipt.status == 1) txToast(receipt.transactionHash);
    } else {
      changeNetwork();
      errorToast("Change network to Avalanche mainnet");
    }
  };
  const mint3 = async () => {
    if (network == 43114) {
      setDisabled(true);
      const tx = await withSigner
        .tier3Mint(amount, royalty, proof)
        .catch(handleError);

      const receipt = await tx.wait().catch(handleError);
      setDisabled(false);
      console.log(receipt);
      if (receipt.status == 1) txToast(receipt.transactionHash);
    } else {
      changeNetwork();
      errorToast("Change network to Avalanche mainnet");
    }
  };

  const toast = useToast();

  const changeNetwork = async () => {
    if (network != 43114 && window.ethereum) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0xa86a",
          },
        ],
      });
    } else if (network != 43114 && !window.ethereum) {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0xa86a",
          },
        ],
      });
    }
  };
  const errorToast = (reason) => {
    const id = Date.now().toString();
    toast({
      isClosable: true,
      duration: 18000,
      position: "bottom-left",
      id: id,
      render: () => (
        <>
          <Box
            shadow={"dark-lg"}
            rounded={"3xl"}
            bgColor="black"
            border={"2px"}
            padding={"0.5"}
            justifyContent="center"
            justifyItems={"center"}
            justifySelf={"center"}
            alignContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            textAlign="center"
            verticalAlign="center"
          >
            <HStack>
              <Heading flex={1} fontSize={"2xl"}>
                Error!
              </Heading>
              <IconButton
                onClick={() => {
                  toast.close(id);
                }}
                rounded="full"
                colorScheme={"blackAlpha"}
              >
                <CloseIcon />
              </IconButton>
            </HStack>
            <Text>{reason}</Text>
          </Box>
        </>
      ),
    });
  };
  const snowtraceLink =
    "https://snowtrace.io/address/" + contractAddress + "#code";
  const joepegsLink = "https://joepegs.com/collections/" + contractAddress;
  function MintButton() {
    const publicSale = publicTime * 1000 < Date.now();
    const privateSale = wlTime * 1000 < Date.now();
    switch (whitelisted) {
      case 3:
        !privateSale ? setDisabled(true) : doNothing(0);
        totalPrice = amount * wlPrice;
        return (
          <>
            <button
              className="mt-4 w-full text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
              disabled={isDisabled}
              onClick={() => mint3()}
            >
              {isDisabled
                ? !privateSale
                  ? "WL sale starts on Dec 1st 4 PM UTC "
                  : "Waiting for tx"
                : "Mint " + amount + " BBB for " + totalPrice + " AVAX 🔺"}
            </button>
          </>
        );

      case 2:
        !privateSale ? setDisabled(true) : doNothing(0);
        totalPrice = amount * wlPrice;
        return (
          <>
            <button
              className="mt-4 w-full text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
              disabled={isDisabled}
              onClick={() => mint2()}
            >
              {isDisabled
                ? !privateSale
                  ? "WL sale starts on Dec 1st 4 PM UTC "
                  : "Waiting for tx"
                : "Mint " + amount + " BBB for " + totalPrice + " AVAX 🔺"}
            </button>
          </>
        );
      case 1:
        !privateSale ? setDisabled(true) : doNothing(0);
        totalPrice = amount * wlPrice;
        return (
          <>
            <button
              className="mt-4 w-full text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
              disabled={isDisabled}
              onClick={() => mint1()}
            >
              {isDisabled
                ? !privateSale
                  ? "WL sale starts on Dec 1st 4 PM UTC "
                  : "Waiting for tx"
                : "Mint " + amount + " BBB for " + totalPrice + " AVAX 🔺"}
            </button>
          </>
        );
      case 0:
        !publicSale ? setDisabled(true) : doNothing(0);
        totalPrice = (amount * (price * 100))/100;
        return (
          <>
            <button
              className="mt-4 w-full text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
              disabled={isDisabled}
              onClick={() => mint()}
            >
              {isDisabled
                ? !publicSale
                  ? "Public sale starts on Dec 1st 6 PM UTC "
                  : "Waiting for tx"
                : "Mint " + amount + " BBB for " + totalPrice + " AVAX 🔺"}
              {isDisabled && publicSale ? <Spinner /> : ""}
            </button>
          </>
        );
      default:
        totalPrice = amount * price;
        return (
          <>
            <button
              className="w-full  text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
              disabled={isDisabled}
              onClick={() => {
                connect();
                errorToast("Change network to Avalanche mainnet");
              }}
            >
              Connect/Change Network
              {isDisabled && publicSale ? <Spinner /> : ""}
            </button>
          </>
        );
    }
  }
  return (
    <>
      <Navbar />
      <div className="mt-4 lg:mt-0 px-4 sm:px-6 max-w-4xl my-6 mx-auto text-gray-800 relative z-40">
        <div className="custom-grid">
          <div className="flex flex-col">
            <div>
              <div className="flex flex-col justify-between w-full py-4 px-4 bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg mb-2">
                <div className="flex justify-between mb-3">
                  <h1 className="text-2xl font-extrabold">Sold Out</h1>
                  <p className="text-3xl flex flex-row gap-2 items-center justify-center font-semibold text-gray-800">
                    <svg width="24" height="24" viewBox="0 0 17 15" fill="red">
                      <path
                        d="M12.4833 8.73993C12.8744 8.0642 13.5057 8.0642 13.8968 8.73993L16.3331 13.0165C16.7242 13.6923 16.4042 14.2436 15.6218 14.2436H10.7139C9.94036 14.2436 9.62028 13.6923 10.0026 13.0165L12.4833 8.73993ZM7.77094 0.506787C8.16214 -0.168929 8.78452 -0.168929 9.17572 0.506787L9.71809 1.48481L10.9984 3.73426C11.3096 4.3744 11.3096 5.13015 10.9984 5.77031L6.70401 13.2121C6.31281 13.8167 5.66376 14.1991 4.94358 14.2436H1.37826C0.59584 14.2436 0.275751 13.7012 0.666969 13.0165L7.77094 0.506787Z"
                        fill="current"
                      ></path>
                    </svg>
                    0.2 <small>AVAX</small>
                  </p>
                </div>
                <div>
                  <p className="font-bold flex justify-between w-full mb-1">
                    {minted} of {maxMintable}
                  </p>
                  <div class="w-full h-2 bg-indigo-200 rounded-full">
                    <div
                      style={{ width: `${(minted / maxMintable) * 100}%` }}
                      className={`h-full text-center text-xs text-white bg-indigo-700 rounded-full`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full py-4 px-4 bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg">
              <div className="mb-6">
                <p className="font-extrabold mb-2">Amount</p>
                <div className="flex justify-between flex-col gap-2 lg:gap-0 lg:flex-row">
                  <div className="flex flex-row justify-between gap-2">
                    <button
                      className="  text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-4 lg:px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                      onClick={() => {
                        amount < 6 ? setAmount(1) : setAmount(amount - 5);
                      }}
                    >
                      -5
                    </button>
                    <button
                      className="  text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                      onClick={() => {
                        amount > 1 ? setAmount(amount - 1) : setAmount(amount);
                      }}
                    >
                      -
                    </button>
                    <h1 className="text-gray-800 transition-all px-6 lg:px-12 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/100 to-white/60 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg">
                      {amount}
                    </h1>
                    <button
                      className="  text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                      onClick={() => {
                        amount < (whitelisted > 0 ? maxWL : max)
                          ? setAmount(amount + 1)
                          : setAmount(amount);
                      }}
                    >
                      +
                    </button>
                    <button
                      className="  text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-4 lg:px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                      onClick={() => {
                        amount > (whitelisted > 0 ? maxWL : max) - 5
                          ? setAmount(whitelisted > 0 ? maxWL : max)
                          : setAmount(amount + 5);
                      }}
                    >
                      +5
                    </button>
                  </div>
                  <button
                    className="  text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                    onClick={() => setAmount(max)}
                  >
                    Max Mint
                  </button>
                </div>
              </div>
              <div className="mb-4 lg:mb-0">
                <p className="font-semibold mb-2">
                  Royalty amount:
                  <span className="font-extrabold mb-1"> {royalty / 100}%</span>
                </p>
                <input
                  className="rangeslider w-full"
                  type="range"
                  min="0"
                  max="750"
                  step="25"
                  defaultValue={5}
                  value={royalty}
                  onChange={(e) => setRoyalty(e.target.value)}
                />
              </div>
              <MintButton />
            </div>
          </div>
          <div className="mt-4 lg:mt-0">
            <img
              src="./nft.jpeg"
              className="rounded-xl shadow-lg mb-2 hidden lg:block"
            />
            <div className="flex gap-2 flex-row justify-between">
              <a
                href="https://twitter.com/BadBizBones"
                target="_blank"
                className=" text-white/50 hover:bg-gray-300 hover:text-blue-600 transition-all p-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
              >
                <FaTwitter size={24} />
              </a>

              <a
                href="https://discord.gg/FHeQdkGZ4A"
                target="_blank"
                className=" text-white/50 hover:bg-gray-300 hover:text-blue-400 transition-all p-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
              >
                <FaDiscord size={24} />
              </a>
              <a
                href="https://joepegs.com/collections/0x84FaB74795F51C3208379924c76abE6e5A36D392"
                target="_blank"
                className="group text-white/50 hover:bg-gray-300 hover:text-gray-800 transition-all p-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
              >
                <img
                  src="./joepegs.png"
                  className="w-6 grayscale group-hover:grayscale-0"
                />
              </a>
              <a
                href="https://snowtrace.io/address/0x84fab74795f51c3208379924c76abe6e5a36d392#code"
                target="_blank"
                className="group text-white/50 hover:bg-gray-300 hover:text-gray-800 transition-all p-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
              >
                <img
                  src="./snow.svg"
                  className="w-6 grayscale group-hover:grayscale-0"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="mt-4 lg:mt-0 px-4 sm:px-6 max-w-4xl my-6 mx-auto text-gray-800 relative z-40">
        <div className="custom-grid">
          <div className="flex flex-col">
            <div>
              <div className="flex flex-col justify-between w-full py-4 px-4 bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg mb-2">
                <div className="flex justify-between mb-3">
                  <h1 className="text-2xl font-extrabold">Mint a BBB on ETH (opening soon)</h1>
                  <p className="text-3xl flex flex-row gap-2 items-center justify-center font-semibold text-gray-800">
                    <svg width="24" height="24" viewBox="0 0 17 15" fill="black">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M311.9 260.8L160 353.6 8 260.8 160 0l151.9 260.8zM160 383.4L8 290.6 160 512l152-221.4-152 92.8z"/></svg> 
                    </svg>
                    ??? <small>ETH</small>
                  </p>
                </div>
                <div>
                  <p className="font-bold flex justify-between w-full mb-1">
                    {/*minted0} of {'???'/*maxMintable}
                  </p>
                  <div class="w-full h-2 bg-indigo-200 rounded-full">
                    <div
                      style={{ width: `${(0*minted / maxMintable) * 100}%` }}
                      className={`h-full text-center text-xs text-white bg-indigo-700 rounded-full`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full py-4 px-4 bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg">
              <div className="mb-6">
                <p className="font-extrabold mb-2">Amount</p>
                <div className="flex justify-between flex-col gap-2 lg:gap-0 lg:flex-row">
                  <div className="flex flex-row justify-between gap-2">
                    <button
                      className="  text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-4 lg:px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                      onClick={() => {
                        amount < 6 ? setAmount(1) : setAmount(amount - 5);
                      }}
                    >
                      -5
                    </button>
                    <button
                      className="  text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                      onClick={() => {
                        amount > 1 ? setAmount(amount - 1) : setAmount(amount);
                      }}
                    >
                      -
                    </button>
                    <h1 className="text-gray-800 transition-all px-6 lg:px-12 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/100 to-white/60 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg">
                      {0/*amount}
                    </h1>
                    <button
                      className="  text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                      onClick={() => {
                        amount < (whitelisted > 0 ? maxWL : max)
                          ? setAmount(amount + 1)
                          : setAmount(amount);
                      }}
                    >
                      +
                    </button>
                    <button
                      className="  text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-4 lg:px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                      onClick={() => {
                        amount > (whitelisted > 0 ? maxWL : max) - 5
                          ? setAmount(whitelisted > 0 ? maxWL : max)
                          : setAmount(amount + 5);
                      }}
                    >
                      +5
                    </button>
                  </div>
                  <button
                    className="  text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
                    onClick={() => setAmount(max)}
                  >
                    Max Mint
                  </button>
                </div>
              </div>
              {/*<div className="mb-4 lg:mb-0">
                <p className="font-semibold mb-2">
                  Royalty amount:
                  <span className="font-extrabold mb-1"> {royalty / 100}%</span>
                </p>
                <input
                  className="rangeslider w-full"
                  type="range"
                  min="0"
                  max="750"
                  step="25"
                  defaultValue={5}
                  value={royalty}
                  onChange={(e) => setRoyalty(e.target.value)}
                />
                    </div>}
              <button
              className="mt-4 w-full text-gray-600 hover:bg-gray-300 hover:text-gray-800 transition-all px-6 py-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg"
              disabled={true}
              onClick={() => mint()}
            >
              {'coming soon'}
              
            </button>
            </div>
          </div>
          <div className="mt-4 lg:mt-0">
            {/*<img
              src="./nft.jpeg"
              className="rounded-xl shadow-lg mb-2 hidden lg:block"
            />
            <div className="flex gap-2 flex-row justify-between">
              <a
                href="https://twitter.com/BadBizBones"
                target="_blank"
                className=" text-white/50 hover:bg-gray-300 hover:text-blue-600 transition-all p-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg" rel="noreferrer"
              >
                <FaTwitter size={24} />
              </a>

              <a
                href="https://discord.gg/FHeQdkGZ4A"
                target="_blank"
                className=" text-white/50 hover:bg-gray-300 hover:text-blue-400 transition-all p-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg" rel="noreferrer"
              >
                <FaDiscord size={24} />
              </a>
              <a
                href="https://joepegs.com/collections/0x84FaB74795F51C3208379924c76abE6e5A36D392"
                target="_blank"
                className="group text-white/50 hover:bg-gray-300 hover:text-gray-800 transition-all p-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg" rel="noreferrer"
              >
                <img
                  src="./joepegs.png"
                  className="w-6 grayscale group-hover:grayscale-0"
                />
              </a>
              <a
                href="https://joepegs.com/collections/0x84FaB74795F51C3208379924c76abE6e5A36D392"
                target="_blank"
                className="group text-white/50 hover:bg-gray-300 hover:text-gray-800 transition-all p-4 font-extrabold items-center justify-center flex flex-row bg-gradient-to-b from-white/60 to-white/30 border-[1px] border-solid border-white border-opacity-30 backdrop-blur-lg rounded-xl shadow-lg" rel="noreferrer"
              >
                <img
                  src="./snow.svg"
                  className="w-6 grayscale group-hover:grayscale-0"
                />
              </a>
            </div>}
          </div>
        </div>
      </div>*/}
    </>
  );
}
export default BBB;
