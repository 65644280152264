import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';

import BBB from './pages/bbb/home';
import Edit from './pages/upgrade/edit';
import Wardrobe from './pages/upgrade/wardrobe';
export const App = () => {

  return (
   <> 

    <Routes>
      <Route path='/*' element={<BBB/>}/> 
      <Route path='/wardrobe' element={<Wardrobe/>}/>
      <Route path={'/wardrobe/:id/'} element={<Edit/>}/>
    </Routes>
  </>
  );
};

export default App;
