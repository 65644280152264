export const FACTORY_STAKING = "0xbdF69BF074b885b411f4Ea0e0b85560d771f3b9A"
export const INVESTING_BBUCKS = "0x74fb09C75515E4A4907063d1784574F8A3077B9f"
export const FACTORY_TOKEN = "0x13EA735E26F5Ac2458457c7bb84C9541e1640a55"
export const BBB_STAKING = "0xA411998F00e73dbbF99e256D3E46cB67A2d3Cd2b"
export const BBUCKS_TOKEN = "0x2F15A30e39093d50aed9c36fE02c3Ea94fbd555c"
export const SUPPLY_TOKEN= "0x13948b5683a105dD0Ae8D697097558b785214c35"
export const BBB = "0x9D17D5c398A6c80f6BBe51C600A40e6c9402dc99"
export const REROLL_CONTRACT='0x2c5F6b80f713C071540bB3704638b8CfD95C6D98'
export const request = {
    toAvaxMainnet:{
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0xa86a",
            rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
            chainName: "Avalanche Network C-Chain",
            nativeCurrency: {
              name: "AVAX",
              symbol: "AVAX",
              decimals: 18,
            },
            blockExplorerUrls: ["https://snowtrace.io/"],
          },
        ],
      },
    toAvaxTestnet:{
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0xa869",
            rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
            chainName: "Avalanche Fuji Testnet",
            nativeCurrency: {
              name: "AVAX",
              symbol: "AVAX",
              decimals: 18,
            },
            blockExplorerUrls: ["https://testnet.snowtrace.io/"],
          },
        ],
      }
}